export const subpages = [
  {
    text: 'Learn More',
    // link: 'Learn More',
    corename: 'Learn More',
    scrollTo: 'about',
    // image: 'formulas',
  },
  {
    text: 'App Store',
    links: {
      en: 'https://apps.apple.com/en/app/workout-history/id6448888087',
      jp: 'https://apps.apple.com/jp/app/workout-history/id6448888087',
      kr: 'https://apps.apple.com/kr/app/workout-history/id6448888087',
    },
    corename: 'App Store',
  },
  {
    text: 'Google Play',
    links: {
      en: 'https://play.google.com/store/apps/details?id=com.suhwanlim.aitrainer',
      jp: 'https://play.google.com/store/apps/details?id=com.suhwanlim.aitrainer',
      kr: 'https://play.google.com/store/apps/details?id=com.suhwanlim.aitrainer',
    },
    corename: 'Google Play',
  },
  //   {
  //     text: 'Podręczniki',
  //     link: 'books',
  //     corename: 'books',
  //     image: 'books',
  //   },
  //   {
  //     text: 'Statystyki',
  //     link: 'statistics',
  //     corename: 'statistics',
  //     image: 'statistics',
  //   },
  //   {
  //     text: 'Informacje',
  //     link: 'info',
  //     corename: 'info',
  //     image: 'info',
  //   },
]
