
import { defineComponent, ref } from 'vue'
import Header from '@/components/Home/Header.vue'

export default defineComponent({
  components: {
    Header,
  },
  setup() {
    const email = ref('')
    const password = ref('')
    const isSubmitting = ref(false)
    const successMessage = ref('')
    const errorMessage = ref('')

    const submitDeletionRequest = async () => {
      isSubmitting.value = true
      successMessage.value = ''
      errorMessage.value = ''

      try {
        const response = await fetch(
          'https://zq4cosevz34rjetxad5id3alba0lynmt.lambda-url.us-east-1.on.aws/', // Lambda URL 사용
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              email: email.value,
              password: password.value,
            }),
            // mode: 'cors', // CORS 요청 모드 명시
          }
        )

        if (!response.ok) {
          const errorResponse = await response.json()
          throw new Error(
            errorResponse.message || 'Failed to process the deletion request.'
          )
        }

        const result = await response.json()
        successMessage.value =
          result.message || 'Your account has been deleted successfully.'
      } catch (error: any) {
        errorMessage.value =
          error.message || 'An error occurred. Please try again.'
      } finally {
        isSubmitting.value = false
      }
    }

    return {
      email,
      password,
      isSubmitting,
      successMessage,
      errorMessage,
      submitDeletionRequest,
    }
  },
})
